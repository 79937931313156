const sizes = {
  0: '0px',
  1: '.25rem',
  full: '100%',
  2: '.0625rem',
  3: '.1875rem',
  4: '.25rem',
  5: '.5rem',
  6: '.625rem',
  7: '1rem',
  8: '1.125rem',
  9: '1.25rem',
  10: '1.5rem',
  11: '1.625',
  12: '1.875rem',
  13: '2rem',
  14: '2.25rem',
  15: '2.5rem',
  16: '3rem',
  17: '3.5rem',
  18: '8rem',
  19: '10rem',
  20: '15rem',
}

export default sizes
