const styles = {
  global: {
    html: {
      height: '-webkit-fill-available',
    },
    body: {
      // height: '-webkit-fill-available',
      margin: '0 auto',
      position:'relative',
      minHeight:'100%',
      fontFamily: "'System-ui','Open Sans', sans-serif",
      // overflow: 'hidden',
    },
    // '#root': {
    //   height: '-webkit-fill-available',
    //   // zoom: '80%',
    // },
    '#__next': {
      height: '-webkit-fill-available',
    },
    'input.chakra-radio__input': {
      display: 'none',
    },
    pre: {
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
    code: {
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
    svg_fill: {
      fill: 'currentColor',
    },
    octicon: {
      verticalAlign: 'baseline !important',
    },
    'img.twemoji': {
      height: '1em',
      width: '1em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
    '*::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    // '*::-webkit-scrollbar-track': {
    // background: 'rgba(0, 0, 0, 0.1)',
    // },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      border: '3px solid rgba(102, 102, 102, 0.6)',
    },
    // "iframeContainer":{
    //           width: '50%',
    //           // "max-width": '450px',
    //           // height: '1334px',
    //           // margin: '0 auto',
    //           // "border-style": 'none',
    //           // "border-color": 'inherit',
    //           // "border-width": '0px',
    //           // "-webkit-transform": 'scale(0.5)',
    //           // "-webkit-transform-origin": '0 0',
    //           // display: 'block',
    //         }
  },
}

export default styles
