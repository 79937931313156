const space = {
  0: '0', 
  1: '.125rem',
  2: '.1875rem',
  3: '.25rem',
  4: '.3125rem',
  5: '.375rem',
  6: '.5rem',
  7: '.625rem',
  8: '.75rem',
  9: '.9375',
  10: '1rem',
  11: '1.25rem',
  12: '1.5rem',
  13: '2rem',
  14: '2.5rem',
  15: '3rem',
  16: '3.5rem',
  17: '4rem',
  18: '8rem',
  19: '15rem',
  20: '16rem',
  21: '30rem',
  22: '32rem',
}

export default space
