import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import styles from './styles'

// Foundation style overrides
import breakpoints from './foundations/breakpoints'
import colors from './foundations/colors'
import sizes from './foundations/sizes'
import fontSizes from './foundations/fontSizes'
import space from './foundations/space'
import shadows from './foundations/shadows'
import variants from './foundations/variants'
import borders from './foundations/borders'

// Component style overrides
// import Modal from './components/Modal'
// import Checkbox from './components/Checkbox'
// import Radio from './components/Radio'

const theme = extendTheme({
  config: {
    useSystemColorMode: true,
    initialColorMode: 'dark',
  },
  styles,
  breakpoints,
  colors,
  // sizes,
  // fontSizes,
  // space,
  shadows,
  variants,
  borders,
  // Other foundational style overrides go here
  components: {
    // Modal,
    // Checkbox,
    // Radio,
    // Other components go here
  },
})

export { breakpoints, colors, sizes, fontSizes, space, shadows }

export default theme
