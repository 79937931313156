import React, { createContext, useState, useEffect, useReducer, useRef, useMemo, useContext, useCallback } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import gql from 'graphql-tag'

import usePersistentState from '../hooks/usePersistedState'
import { AccessRights, Group, Maybe, Template, User, UserSetting } from '../types/GraphQL'
import { useCookies } from 'react-cookie'

const query = gql`
  query getUser($username: String!, $now: DateTime!) {
    getUser(username: $username) {
      username
      userId
      hasEmail {
        id
        email
      }
      hasRole
      hasSettings {
        id
        name
        value
        values
        isTrue
      }
      hasTypes
      hasGroupAccess {
        id
        hasRights {
          id
          name
          isTrue
        }
        forGroup {
          slug
          name
          isContact {
            id
            organization
            abbreviation
          }
        }
      }
      isContact {
        id
        prefix
        firstName
        middleName
        lastName
        suffix
        preferredName
        name
        gender
        field
        birthDate
        gravatar
        sentBy {
          id
          organization
        }
        hasBBFund {
          bbid
          description
        }
      }
      isAccount {
        id
        hasPlans(filter: { not: { end: { lt: $now } } }) {
          id
          plan {
            id
            name
          }
        }
        hasAddOns(filter: { not: { end: { lt: $now } } }) {
          id
          addOn {
            id
            name
          }
        }
      }
    }
    queryGroup @cascade {
      # cascade here removes unclaimed groups from admins.
      id
      slug
      name
      isContact {
        id
        organization
        abbreviation
      }
    }

    queryTemplate {
      id
      name
      subject
      content
    }
  }
`

export type UserSettings = Record<
  any,
  UserSetting & {
    val: string | boolean | string[]
  }
>
export interface UserData {
  logout?: React.DispatchWithoutAction
  userToken?: string
  adminToken?: string
  token?: string
  setToken?: (token: string, expires: string) => void
  username?: string
  email?: string
  expires?: any
  isLoggedIn?: boolean
  canBeAdmin?: boolean
  isActingAdmin?: boolean
  setLogin?: (token: string, username: string, expires: any, admin: string) => void
  data?: User
  name?: string
  groups?: Group[]
  emailTemplates?: Template[]
  userContactId?: string
  groupRights?: {
    [key: string]: AccessRights[]
  }
  error?: boolean | ApolloError
  settings?: UserSettings
  // addSetting?
  // updateSetting?
}

const UserContext = createContext<UserData>(null)

function UserContextProvider({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'userToken', 'adminToken', 'username'])

  // console.log(cookies)
  const [, logout] = useReducer((x) => {
    localStorage.clear()
    if (typeof window !== 'undefined') {
      removeCookie('userToken', { path: '/' })
      removeCookie('adminToken', { path: '/' })
      removeCookie('token', { path: '/' })
      removeCookie('username', { path: '/' })
      window.location.reload()
    }
    return x + 1
  }, 0)

  const userQuery = useRef(query)
  const now = useRef(new Date())
  // const logout = logout
  const [awaitingUser, setAwaitingUser] = useState(true)
  // const [userToken, setUserToken] = usePersistentState<string>('user.userToken', null)
  const userToken: string = cookies.userToken || null
  const setUserToken = useCallback(
    (userToken: string, expires: string) => setCookie('userToken', userToken, { expires: new Date(expires), path: '/', sameSite: true }),
    [setCookie]
  )
  // const [adminToken, setAdminToken] = usePersistentState<string>('user.adminToken', null)
  const adminToken: string = cookies.adminToken || null
  const setAdminToken = useCallback(
    (adminToken: string, expires: string) => setCookie('adminToken', adminToken, { expires: new Date(expires), path: '/', sameSite: true }),
    [setCookie]
  )
  // const [token, setToken] = usePersistentState<string>('user.token', null)
  const token: string = cookies.token || null
  const setToken = useCallback(
    (token: string, expires: string) => setCookie('token', token, { expires: new Date(expires), path: '/', sameSite: true }),
    [setCookie]
  )
  // const [username, setUsername] = usePersistentState<string>('user.username', null)
  const username: string = cookies.username || null
  const setUsername = useCallback(
    (username: string, expires: string) => setCookie('username', username, { expires: new Date(expires), path: '/', sameSite: true }),
    [setCookie]
  )

  const [expires, setExpires] = usePersistentState('user.expires', false)

  // const userToken = userToken
  // const adminToken = adminToken
  // const token = token
  // const setToken = setToken
  // const username = username
  // const expires = expires
  const isLoggedIn = !!token
  const canBeAdmin = !!adminToken
  const isActingAdmin = !!(adminToken && adminToken === token)
  useEffect(() => {
    if (token && username) {
      setAwaitingUser(false)
    }
  }, [token, username])
  const setLogin = useCallback(
    (token, username, expires, admin) => {
      if (token) {
        setUsername(username, expires)
        setToken(token, expires)
        setUserToken(token, expires)
        if (admin) {
          setCookie('adminToken', admin, { expires: new Date(expires), path: '/', sameSite: true })
          setAdminToken(admin, expires)
        }
        setExpires(expires)
      }
    },
    [setAdminToken, setCookie, setExpires, setToken, setUserToken, setUsername]
  )
  const context: Record<string, any> = { headers: { auth: token } }
  const userData = useQuery<{ getUser: Maybe<User>; queryGroup: Maybe<Group[]>; queryTemplate: Maybe<Template[]> }>(userQuery.current, {
    context,
    skip: awaitingUser,
    variables: { username, now: now.current },
  })
  const data = userData.data?.getUser
  const name = userData.data?.getUser?.isContact?.name || ''
  const groups = useMemo(() => {
    return userData.data?.queryGroup || []
  }, [userData.data?.queryGroup])
  const groupRightsTemp = useMemo(() => userData.data?.getUser?.hasGroupAccess || [], [userData])
  const userContactId = userData.data?.getUser?.isContact?.id
  const groupRights = useMemo(() => {
    const groupAccess: {
      [key: string]: AccessRights[]
    } = {}
    groupRightsTemp.forEach(({ hasRights = [], forGroup: { slug } }) => {
      if (!Array.isArray(hasRights) || hasRights.length === 0) return null
      groupAccess[slug] = hasRights.filter(({ isTrue }) => isTrue).map(({ name }) => name)
      return null
    })
    return groupAccess
  }, [groupRightsTemp])

  // NOTE: Moved the VBM blackbaud finances menu add to the NavMenuContext

  const error = userData.loading ? false : userData.error
  const email = userData.data?.getUser?.hasEmail?.email
  const emailTemplates = userData.data?.queryTemplate
  const settingsTemp = userData?.data?.getUser?.hasSettings
  const settings = useMemo(() => {
    const settings = {}
    if (Array.isArray(settingsTemp))
      settingsTemp.forEach(({ name, value, values, isTrue, ...setting }) => {
        let val: string | string[] | boolean = null
        if (typeof value === 'string') val = value
        if (Array.isArray(values) && values.length > 0) val = values
        if (typeof isTrue === 'boolean') val = isTrue
        settings[name] = { ...setting, name, value, values, isTrue, val }
      })
    return settings
  }, [settingsTemp])
  const firstRender = useRef(true)
  useEffect(() => {
    // issue a page reload when either a user logs in or an admin switches tokens
    // use firstRender ref to prevent reload on initial page load
    if (typeof window !== 'undefined') {
      if (firstRender.current) {
        firstRender.current = false
      } else {
        // FIXME: This somehow is causing a refresh loop. maybe due to how react 18 changed how useEffect was processed??
        // window.location.reload()
      }
    }
  }, [token])

  const user: UserData = useMemo(() => {
    const user: UserData = {
      logout,
      userToken,
      adminToken,
      token,
      setToken,
      username,
      email,
      expires,
      isLoggedIn,
      canBeAdmin,
      isActingAdmin,
      setLogin,
      data,
      name,
      groups,
      emailTemplates,
      userContactId,
      groupRights,
      error,
      settings,
      // addSetting,
      // updateSetting,
    }
    return user
  }, [
    adminToken,
    canBeAdmin,
    data,
    email,
    emailTemplates,
    error,
    expires,
    groupRights,
    groups,
    isActingAdmin,
    isLoggedIn,
    name,
    setLogin,
    setToken,
    settings,
    token,
    userContactId,
    userToken,
    username,
  ])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export { UserContextProvider, UserContext }

export default UserContext

export const useUserContext = () => useContext(UserContext)
