import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import Theme from '../theme'
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'

import { UserContextProvider } from '@contexts/UserContext'
// FIXME: Don't import the whole package.json !!
import packageInfo from '../../package.json'
import { AppProps } from 'next/app'
import { PageLayoutProps, PrimaryLayouts, SecondaryLayouts } from '@localTypes/Page'
import dynamic from 'next/dynamic'

const { version } = packageInfo

if (typeof window !== 'undefined') {
  // NOTE: This is a reset when version changes of the app
  // TODO: Make this reset only happen on major version changes once this reaches the 1.0 stage
  // A major version change should only happen when breaking changes come in such that would cause
  // any currently logged in users problems if they were not logged out and logged back in to
  // reset their local storage
  const lastSeenVersion = localStorage.getItem('version')
  if (lastSeenVersion !== version) {
    localStorage.clear()
    localStorage.setItem('version', version)
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    BlackbaudGift: {
      keyFields: ['bbid'],
    },
    BlackbaudSplits: {
      keyFields: ['bbid'],
    },
    BlackbaudFund: {
      keyFields: ['bbid'],
    },
    SyncedContact: {
      keyFields: ['bbid'],
    },
    AreaCode: {
      keyFields: ['name'],
    },
    Country: {
      keyFields: ['name'],
    },
    State: {
      keyFields: ['name'],
    },
    User: {
      keyFields: ['username'],
    },
    Zip: {
      keyFields: ['zip'],
    },
  },
})
const link = createHttpLink({
  // NOTE our API link is not private as private data is secured through access controls
  uri: 'https://primo-yam-7925.us-west-2.aws.cloud.dgraph.io/graphql',
})

const client = new ApolloClient({
  cache,
  link,
})

type AppPropsExt = AppProps & {
  Component: PageLayoutProps
}

const PrimaryLayoutImports: { [k in PrimaryLayouts]: React.ComponentType } = {
  AppLayout: dynamic(() => import('@layouts/AppLayout')),
  // LandingPageLayout: dynamic(() => import('@layouts/LandingPageLayout')),
}

const SecondaryLayoutImports: { [k in SecondaryLayouts]: React.ComponentType } = {
  FinancesLayout: dynamic(() => import('@layouts/FinancesLayout')),
  // SettingsLayout: dynamic(() => import('@layouts/SettingsLayout')),
}

function App({ Component, pageProps }: AppPropsExt) {
  const PrimaryLayout = PrimaryLayoutImports?.[Component.primaryLayout] ?? React.Fragment
  const SecondaryLayout = SecondaryLayoutImports?.[Component.secondaryLayout] ?? React.Fragment

  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <ChakraProvider theme={Theme}>
            <PrimaryLayout>
              <SecondaryLayout>
                <Component {...pageProps} />
              </SecondaryLayout>
            </PrimaryLayout>
          </ChakraProvider>
        </UserContextProvider>
      </ApolloProvider>
    </CookiesProvider>
  )
}
export default App
