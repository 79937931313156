import { theme } from '@chakra-ui/react'

const { colors: tColors } = theme

// override any default theme colors with our custom defined colors
const colors = {
  mb_gray: {
    50: '#fefefe', //offwhite
    100: '#f5f6f7', //porcelain
    200: '#ececec', //whiteStallion
    300: '#e6e6e6', //mercury
    400: '#cfcfcf', //sidewalkGray
    500: '#999999', //dustyGray
    600: '#8d8d8d', //cathedralGray
    700: '#777777', //boulder
    800: '#1A202C', //grayDark
    900: '#212529', //sharkGray
  },
  mb_steel: {
    50: '#f8f9fa', //novaWhite
    100: '#eff1f4', //grey
    200: '#ebeff2', //athensGray
    300: '#dfe3e8', //pearlGray
    400: '#d0d0d0', //gray2
    500: '#6c757d', //gray
    600: '#454f5b', //riverBed
    700: '#45455b', //gunPowder
    800: '#3e4155', //oldNavy
    900: '#343943', //whaleGray
  },
  mb_atomic: {
    50: '#f8f9fa', //
    100: '#CBCBCB', //sidebar white text
    200: '#a9b2c0', //console text
    300: '#7e84af', //
    400: '#d0d0d0', //
    500: '#6c757d', //
    600: '#363c48', //scrollbar color
    700: '#45455b', //
    800: '#272c35', //text background
    900: '#20252c', //sidebar
  },
  mb_red: {
    50: '#FFE5E9',
    100: '#FBA7A2',
    200: '#F7685B', //carnation
    300: '#F95869',
    400: '#FA4776', //violetRed
    500: '#E83E8C', //pink
    600: '#E23A69',
    700: '#DC3545', //hotLips
    800: '#7D1B23',
    900: '#1E0001',
  },
  mb_green: {
    50: '#e3fee4',
    100: '#BAF7BC',
    200: '#90EF93',
    300: '#65e76a', //emerald
    400: '#47C758',
    500: '#28a745', //green
    600: '#1aa620', //lizardGreen
    700: '#0D5F10',
    800: '#073C08',
    900: '#001800',
  },
  mb_yellow: {
    50: '#fffadb',
    100: '#FDE572',
    200: '#FCDA3E',
    300: '#FACF09', //supernova
    400: '#FFC107', //yellow
    500: '#ECC30C',
    600: '#D9C511', //goldTips
    700: '#7A6D09',
    800: '#4B4105',
    900: '#1B1500',
  },
  mb_orange: {
    50: '#FFEFDE',
    100: '#FAC190',
    200: '#F49342', //fruitPunch
    300: '#F9892B',
    400: '#FD7E14', //orange
    500: '#C5610F',
    600: '#8D440A',
    700: '#552705',
    800: '#391903',
    900: '#1D0A00',
  },
  mb_purple: {
    50: '#F2E5FF',
    100: '#C7A8EF',
    200: '#9C6ADE', //mediumPurple
    300: '#6F42C1', //purple
    400: '#6B29DA',
    500: '#6610F2', //indigo
    600: '#500CBD',
    700: '#390888',
    800: '#230453',
    900: '#0C001E',
  },
  mb_blue: {
    50: '#DFF0FF',
    100: '#78C6F8',
    200: '#44B1F5', //snowConeGreen NOTE: new color replaces one instance on preferred phone background
    300: '#109CF1', //utahSky
    400: '#2792F8', //bigCountryBlue NOTE: new color replaces one instance on FilterBuilder row indent
    500: '#3D88FF', //rockyMountainSky
    600: '#007BFF', //blue
    700: '#004390',
    800: '#002758',
    900: '#000A20',
  },
  mb_turquoise: {
    50: '#D8FBFF',
    100: '#B7ECEC', //cruise
    200: '#8CE3F4',
    300: '#61DAFB', //babyBoyBlue
    400: '#3CBEDA',
    500: '#17A2B8', //cyan
    600: '#148799',
    700: '#106B7A',
    800: '#0C505B',
    900: '#08343B',
  },
  mb_teal: {
    50: '#DDFEF5',
    100: '#C9F0F8',
    200: '#B4E1FA', //sail
    300: '#8FDBE2',
    400: '#6AD5C9',
    500: '#45CFB0',
    600: '#20C997', //teal
    700: '#10765F',
    800: '#084C43',
    900: '#002227',
  },
}

// add use case named colors

// Actions
colors.success = colors.mb_green[500]
colors.danger = colors.mb_red[700]
colors.delete = colors.mb_red[200]
colors.info = colors.mb_turquoise[500]
colors.warning = colors.mb_yellow[400]
colors.error = colors.mb_red[700]
colors.highlight = colors.mb_yellow[600]
colors.accent = colors.mb_orange[200]
// Main
colors.text = colors.mb_steel[600]
colors.primary = colors.mb_blue[500]
colors.secondary = colors.mb_blue[400]
colors.muted = colors.mb_gray[300]
colors.dark = colors.mb_gray[800]
colors.darken = colors.mb_blue[800]
// Backgrounds
colors.background = tColors.white
colors.darkBackground = colors.mb_steel[900]
colors.viewBackground = tColors.white
colors.sidebarBackground = colors.mb_gray[100]
colors.miniProfileBackground = colors.mb_steel[100]

colors.selectedBackground = colors.mb_gray[100]
colors.inputBackground = colors.mb_gray[200]
colors.activeBackground = colors.mb_gray[700]
colors.hoverBackground = colors.mb_steel[400]
colors.buttonBackground = colors.mb_steel[200]
// Items
colors.gray1 = colors.mb_steel[300]
colors.darkBorder = colors.mb_gray[600]
colors.buttonColor = colors.mb_gray[600]
colors.darkLine = colors.mb_gray[500]
colors.labelBorder = colors.mb_steel[400]

// NOTE: this an override of colors for the useColor hook only. This does not not change the colors when called outside of the useColor hook.
export const colorModes = {
  ...tColors,
  ...colors,
  //color: [light, dark]
  text: [colors.mb_steel[600], colors.mb_atomic[200]],
  // Backgrounds
  background: [tColors.white, colors.mb_steel[900]],
  sidebarBackground: [colors.mb_gray[100], colors.mb_atomic[900]],
  viewBackground: [tColors.white, colors.mb_atomic[800]],
  miniProfileBackground: [colors.mb_steel[100], colors.mb_atomic[900]],
  buttonBackground: [colors.mb_steel[200], colors.mb_gray[800]],
  headerBackground: [colors.primary, colors.mb_atomic[900]],
  // Elements
  buttonColor: [colors.mb_gray[600], colors.mb_gray[100]],
  shadow: ['rgba(0,0,0,0.5)', 'rgba(150,150,150,0.5)'],
  darkBorder: [tColors.black, colors.mb_atomic[200]],
  navTabBg: [tColors.white, colors.mb_atomic[800]],
  navTabTextActive: [tColors.black, colors.mb_atomic[100]],
  navTabTextInactive: [tColors.black, colors.mb_atomic[100]],
  supporterGiftsDetail: [colors.mb_steel[50], colors.mb_atomic[900]],
  tabIcon: [tColors.blue[600], tColors.blue[400]],
  addressIcons: [colors.mb_blue[300], colors.mb_atomic[100]],
  logoColor: [colors.mb_atomic[900], 'white'],
  star: [colors.mb_yellow[100], colors.mb_yellow[700]],

  ButtonGridActiveBackground: [colors.mb_gray[600], colors.mb_atomic[600]],
  ButtonGridActiveIcon: [colors.mb_gray[50], ''],

  ButtonGridInactiveBg: [colors.mb_steel[50], ''],
  ButtonGridInactiveIcon: [colors.mb_gray[600], colors.mb_atomic[100]],
  searchBackground: [colors.mb_gray[200], ''],
  miniProfileTagContainerBG: ['#eaeaea', colors.mb_atomic[600]],
  reactionBg: ['#eaeaea', '#20252c'],
  colorModeToggleButtonBG: ['', ''],
}

export default colors
