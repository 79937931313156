const variants = {
  nav: {
    color: 'secondary',
    textDecoration: 'none',
  },
  filter: {
    color: 'secondary1',
    textDecoration: 'none',
  },
  avatar: {
    boxSizing: 'border-box',
    minWidth: 0,
    maxWidth: '100%',
    width: 48,
    height: 48,
    margin: 0,
    borderRadius: '100%',
  },
}

export default variants
