const fontSizes = {
  0: '.5rem',
  1: '.625rem',
  2: '.75rem',
  3: '.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '2rem',
  8: '3rem',
  9: '4rem',
  10: '6rem',
}

export default fontSizes
