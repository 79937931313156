import { createBreakpoints } from '@chakra-ui/theme-tools'

// docs: https://chakra-ui.com/docs/features/responsive-styles

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  sm: '40em', //30em default
  md: '52em', //48em default
  lg: '64em', //62em default
  //   xl: '80em', //80em default
  //   2xl: '96em' //96em default
})

export default breakpoints
