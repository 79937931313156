import { useState, useEffect, Dispatch, SetStateAction } from 'react'

// called anywhere usually as usePersistentState where state is needed stored between page reloads

export default function usePersistedState<S>(key: string, defaultValue: S): [S, Dispatch<SetStateAction<S>>] {
  let current = null
  if (typeof window !== 'undefined') {
    current = JSON.parse(localStorage.getItem(key))
  }

  const [state, setState] = useState<S>(current ?? defaultValue)
  // useEffect(() => {
  //   // console.log(current);

  //   if (state !== current) {

  //     setState(current ?? defaultValue)
  //   }
  // }, [defaultValue, key])

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])
  return [state, setState]
}
